import React, { useEffect, useState } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import AciAirKeys from 'src/constants/locale/key/AciAir'
import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Typography,
  IconButton,
  Switch as MuiSwitch,
  withStyles,
  Paper,
  Popover,
  Divider,
  Checkbox
} from '@material-ui/core'
import { components, DataFlattener, useTranslation } from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import moment from 'moment'
import { matchPath, useLocation } from 'react-router-dom'
import { useFormContext, useWatch } from "react-hook-form";
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon';
import CngSection from 'src/components/cngcomponents/CngSection'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import pathMap from '../../../paths/pathMap'
import PartyListSection from './PartyListSection'
import ItemSection from './ItemSection'
import StatusNotificationSection from './StatusNotificationSection'

const {
  form: {
    field: {
      CngCheckboxField,
      CngTextField,
      CngDateTimeField,
      CngCountryAutocompleteField,
      CngCodeMasterAutocompleteField
    }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  messageFunction: "",
  id: "",
  msgType: "",
  formType: "687",
  customsProc: "",
  carrierCode: "",
  originalCCN: "",
  ccn: "",
  mot: "4",
  specialIns: "",
  deliveryCountry: "",
  deliveryCity: "",
  deliveryTerminalName: "",
  scheduleDateAndTime: "",
  scheduledSubmissionFlag: false,
  templateFlag: false,
  templateName: "",
  hdrStatus: "",
  modeOfSubmission: "UI",
  aciAirRouteList: [],
  aciAirPartyList: [],
  aciAirManifestQtyList: [],
  aciAirEquipmentList: [],
  aciAirItemList: [],
  aciAirStatusNotifyList: [],
  oriCreatedBy: "",
  oriUpdatedBy: "",
  oriSubmittedBy: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

const StyledPopoverWrapper = withStyles((theme) => ({
  root: {
    maxWidth: '100%',
    padding: '8px 16px',
    width: (props) => props.width || theme.breakpoints.values.sm,
    '& .MuiFormControlLabel-root': {
      display: 'flex',
      margin: 0,
      '& .MuiFormControlLabel-label': {
        flexGrow: 1
      }
    }
  }
}))(Paper)

function Fields({ disabled, shouldHideMap }) {
  const [formSettings, setFormSettings] = useState({ hideOptionalFields: false })
  const [formSettingsPopover, setFormSettingsPopover] = useState({ anchorEl: null })
  const { translate } = useTranslation(Namespace.ACI_AIR)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { setValue } = useFormContext()

  const [
    templateFlag,
    ccn,
    scheduledSubmissionFlag,
    hdrStatus
  ] = useWatch({
    name: [
      'templateFlag',
      'ccn',
      'scheduledSubmissionFlag',
      'hdrStatus'
    ]
  })

  const location = useLocation()

  const editPage = !!matchPath(location.pathname, { path: pathMap.ACI_AIR_S10_EDIT_VIEW })
  const addPage = !!matchPath(location.pathname, { path: pathMap.ACI_AIR_S10_ADD_VIEW })

  const isEditable = editPage && templateFlag && hdrStatus === 1002
  const isCCNEditable = editPage && hdrStatus !== 1005

  useEffect(() => {
    const fileForUserDetails = FileForUserGetUserDetails()

    if (fileForUserDetails) {
      const { fileForUserId, fileForUserPartyId, fileForUserLoginId } =
        fileForUserDetails

      setValue('fileForUserId', fileForUserId)
      setValue('fileForUserPartyId', fileForUserPartyId)
      setValue('fileForUserLoginId', fileForUserLoginId)
    }
  }, [])

  function makeTranslatedTextsObject() {
    let header = translate(
      Namespace.ACI_AIR,
      AciAirKeys.HEADER
    )
    let schedule = translate(
      Namespace.ACI_AIR,
      AciAirKeys.SCHEDULE
    )
    let messageFunction = translate(
      Namespace.ACI_AIR,
      AciAirKeys.MESSAGE_FUNCTION
    )
    let customsProc = translate(
      Namespace.ACI_AIR,
      AciAirKeys.CUSTOMS_PROC
    )
    let carrierCode = translate(
      Namespace.ACI_AIR,
      AciAirKeys.CARRIER_CODE
    )
    let originalCCN = translate(
      Namespace.ACI_AIR,
      AciAirKeys.ORIGINAL_C_C_N
    )
    let ccn = translate(
      Namespace.ACI_AIR,
      AciAirKeys.SRN
    )
    let specialIns = translate(
      Namespace.ACI_AIR,
      AciAirKeys.SPECIAL_INS
    )
    let deliveryCountry = translate(
      Namespace.ACI_AIR,
      AciAirKeys.DELIVERY_COUNTRY
    )
    let deliveryCity = translate(
      Namespace.ACI_AIR,
      AciAirKeys.DELIVERY_CITY
    )
    let deliveryTerminalName = translate(
      Namespace.ACI_AIR,
      AciAirKeys.DELIVERY_TERMINAL_NAME
    )
    let scheduleDateAndTime = translate(
      Namespace.ACI_AIR,
      AciAirKeys.SCHEDULE_DATE_TIME
    )
    let scheduledSubmissionFlag = translate(
      Namespace.ACI_AIR,
      AciAirKeys.SCHEDULE_SUBMISSION_FLAG
    )
    let templateFlag = translate(
      Namespace.ACI_AIR,
      AciAirKeys.TEMPLATE_FLAG
    )
    let templateName = translate(
      Namespace.ACI_AIR,
      AciAirKeys.TEMPLATE_NAME
    )

    return {
      header,
      schedule,
      messageFunction,
      customsProc,
      carrierCode,
      originalCCN,
      ccn,
      specialIns,
      deliveryCountry,
      deliveryCity,
      deliveryTerminalName,
      scheduleDateAndTime,
      scheduledSubmissionFlag,
      templateFlag,
      templateName,
    }
  }

	return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card variant='outlined'>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={3}>
                      <Typography variant='h5' style={{ fontWeight: 600 }}>
                        {translatedTextsObject.header}
                      </Typography>
                      <Typography color='textSecondary' variant='caption'>
                        <Typography variant='inherit' color='error'>*</Typography>
                        Mandatory fields
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Grid container spacing={2}>
                        <Grid item xs>
                          <Grid container spacing={2}>
                            <Grid item xs='auto'>
                              <CngCheckboxField
                                label={
                                  <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                                    {translatedTextsObject.templateFlag}
                                  </Typography>
                                }
                                name='templateFlag'
                                disabled={disabled || isEditable}
                                onChange={(e) => setValue('templateFlag', e.target.checked)}
                                size='small'
                                style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
                              />
                            </Grid>
                            <Grid item xs>
                              <CngTextField
                                required
                                name='templateName'
                                inputProps={{ maxLength: 50 }}
                                disabled={!templateFlag || disabled || isEditable}
                                isRequired={templateFlag}
                                onBlur={(e) => setValue('templateName', e.target.value.toUpperCase().trim())}
                                placeholder={translatedTextsObject.templateName}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs='auto'>
                          <Divider orientation='vertical' />
                        </Grid>
                        <Grid item xs='auto'>
                          <IconButton
                            onClick={(event) => setFormSettingsPopover({ anchorEl: event.currentTarget })}
                            size='small'
                          >
                            <Box
                              display='flex'
                              alignItems='center'
                              justifyContent='center'
                              width={24}
                              height={24}
                            >
                              <FontAwesomeIcon icon={['fal', 'ellipsis']} />
                            </Box>
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.carrierCode}>
                      <CngTextField
                        name='carrierCode'
                        inputProps={{ maxLength: 4 }}
                        label={translatedTextsObject.carrierCode}
                        disabled={disabled}
                        isRequired
                        onBlur={(e) => {
                          const value = e.target.value.toUpperCase()

                          setValue('carrierCode', value)

                          if (ccn === '' || ccn.length <= 4) {
                            setValue('ccn', value)
                          }
                        }}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.originalCCN}>
                      <CngTextField
                        name='originalCCN'
                        inputProps={{ maxLength: 25 }}
                        label={translatedTextsObject.originalCCN}
                        disabled={disabled}
                        isRequired
                        size='small'
                        onBlur={(e) => setValue('originalCCN', e.target.value.toUpperCase().trim())}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.ccn}>
                      <CngTextField
                        name='ccn'
                        inputProps={{ maxLength: 25 }}
                        label={translatedTextsObject.ccn}
                        disabled={disabled || isCCNEditable}
                        isRequired
                        size='small'
                        onBlur={(e) => setValue('ccn', e.target.value.toUpperCase().trim())}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.customsProc}>
                      <CngCodeMasterAutocompleteField
                        name='customsProc'
                        label={translatedTextsObject.customsProc}
                        disabled={disabled}
                        codeType='AIR_CUSTOMS_PROCEDURE'
                        isRequired
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} shouldHide={shouldHideMap.specialIns || formSettings.hideOptionalFields}>
                      <CngTextField
                        name='specialIns'
                        inputProps={{ maxLength: 60 }}
                        label={translatedTextsObject.specialIns}
                        disabled={disabled}
                        onBlur={(e) => setValue('specialIns', e.target.value.toUpperCase().trim())}
                        size='small'
                      />
                    </CngGridItem>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <CngSection
                    title={translatedTextsObject.schedule}
                    subheader={
                      <Typography color='textSecondary' variant='caption'>
                        <Typography variant='inherit' color='error'>*</Typography>
                        Mandatory fields
                      </Typography>
                    }
                  >
                    <Grid container spacing={1}>
                      <CngGridItem xs={12} md={4} shouldHide={shouldHideMap.deliveryCountry}>
                        <CngCountryAutocompleteField
                          name='deliveryCountry'
                          label={translatedTextsObject.deliveryCountry}
                          disabled={disabled}
                          isRequired
                          searchableFields={['data.code', 'data.descriptionEn']}
                          size='small'
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} md={4} shouldHide={shouldHideMap.deliveryCity}>
                        <CngTextField
                          name='deliveryCity'
                          inputProps={{ maxLength: 25 }}
                          label={translatedTextsObject.deliveryCity}
                          disabled={disabled}
                          isRequired
                          onBlur={(e) => setValue('deliveryCity', e.target.value.toUpperCase().trim())}
                          size='small'
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} md={4} shouldHide={shouldHideMap.deliveryTerminalName || formSettings.hideOptionalFields}>
                        <CngTextField
                          name='deliveryTerminalName'
                          inputProps={{ maxLength: 25 }}
                          label={translatedTextsObject.deliveryTerminalName}
                          disabled={disabled}
                          isRequired
                          onBlur={(e) => setValue('deliveryTerminalName', e.target.value.toUpperCase().trim())}
                          size='small'
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.scheduledSubmissionFlag || shouldHideMap.scheduleDateAndTime}>
                        <Card variant='outlined'>
                          <Box padding={1} paddingLeft={2}>
                            <Grid alignItems='center' container spacing={2}>
                              <CngGridItem xs={12} sm={6}>
                                <FormControlLabel
                                  control={<Checkbox checked={scheduledSubmissionFlag} />}
                                  label={
                                    <Typography
                                      color='textSecondary'
                                      variant='body2'
                                      style={{ fontWeight: 600 }}
                                    >
                                      {translatedTextsObject.scheduledSubmissionFlag}
                                    </Typography>
                                  }
                                  onChange={(e) => setValue('scheduledSubmissionFlag', e.target.checked)}
                                />
                              </CngGridItem>
                              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.scheduleDateAndTime}>
                                <CngDateTimeField
                                  name='scheduleDateAndTime'
                                  label={translatedTextsObject.scheduleDateAndTime}
                                  format='yyyy-MM-DD HH:mm:ss'
                                  disabled={disabled || !scheduledSubmissionFlag}
                                  isRequired
                                  size='small'
                                  shouldDisableDate={(date) => moment().isAfter(moment(date))}
                                />
                              </CngGridItem>
                            </Grid>
                          </Box>
                        </Card>
                      </CngGridItem>
                    </Grid>
                  </CngSection>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <PartyListSection formSettings={formSettings} />
        </Grid>
        <Grid item xs={12}>
          <ItemSection formSettings={formSettings} />
        </Grid>
        <Grid item xs={12}>
          <StatusNotificationSection formSettings={formSettings} />
        </Grid>
        <CngGridItem xs={12} shouldHide={shouldHideMap.messageFunction}>
          {editPage && (
            <CngCodeMasterAutocompleteField
              name='messageFunction'
              label={translatedTextsObject.messageFunction}
              disabled={disabled}
              codeType='MESSAGE_FUNCTION'
              isRequired
            />
          )}
          {addPage && (
            <CngCodeMasterAutocompleteField
              name='messageFunction'
              label={translatedTextsObject.messageFunction}
              disabled={disabled}
              codeType='MESSAGE_FUNCTION_ADD'
              isRequired
            />
          )}
        </CngGridItem>
      </Grid>
      <FormSettingsPopover
        anchorEl={formSettingsPopover.anchorEl}
        open={formSettingsPopover.anchorEl ? true : false}
        onClose={() => setFormSettingsPopover({ anchorEl: null })}
        settings={formSettings}
        onChangeSettings={(setting) => setFormSettings((prev) => ({ ...prev, ...setting }))}
      />
    </>
	)
}

function FormSettingsPopover(props) {
  const { anchorEl, open, onClose, settings, onChangeSettings } = props

  return (
    <Popover
      anchorEl={anchorEl}
      onClose={onClose}
      open={open}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <StyledPopoverWrapper width={300}>
        <FormControlLabel
          control={
            <MuiSwitch
              checked={settings.hideOptionalFields}
              edge='end'
              onChange={(event) =>
                onChangeSettings({ hideOptionalFields: event.target.checked })
              }
            />
          }
          label='Hide optional fields'
          labelPlacement='start'
        />
      </StyledPopoverWrapper>
    </Popover>
  )
}

function toClientDataFormat(serverData) {
  let localData = { ...serverData }

  localData.aciAirPartyList.forEach((party, index) => {
    party['_id'] = index
    party['saveFlag'] = !!party.saveFlag
  })

  localData.aciAirItemList.forEach((item, index) => {
    item['_id'] = index
    item['saveFlag'] = !!item.saveFlag
  })

  localData.aciAirStatusNotifyList.forEach((status, index) => {
    status['_id'] = index
  })
  
  return localData
}

function toServerDataFormat(localData) {
  let serverData = { ...localData }
  return DataFlattener.unflatten(serverData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
