import { Box, Card, Grid } from '@material-ui/core'
import React, { useRef, useEffect, useState } from 'react'
import {
  components,
  DataFlattener,
  useServices,
  useTranslation,
  constants
} from 'cng-web-lib'
import { useLocation, useParams } from 'react-router-dom'
import pathMap from '../../../paths/pathMap'
import Stepper from '../../../components/aciacehighway/Stepper'
import FormProperties from './FormProperties'
import withFormState from '../../../components/aciacehighway/hocs/withFormState'
import { useFormContext } from 'react-hook-form'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import AciAirSsrApiUrls from '../../../apiUrls/AciAirSsrApiUrls'
import Namespace from '../../../constants/locale/Namespace'
import AciAirKeys from '../../../constants/locale/key/AciAir'
import ConfirmDialog from '../../common/ConfirmDialog'
import axios from 'axios'
import ChargeTypeDialog from '../../common/ChargeTypeDialog'
import { FileForUserGetUserDetails, GetLoggedInUserPartyName } from 'src/common/FileForUserCommon'

const {
  button: { CngButton },
  form: { CngAddForm },
  table: {
    useFetchCodeMaintenanceLookup
  }
} = components

function AddPage(props) {
  const { history, onSetLoading, showNotification } = props

  const [submitDialog, setSubmitDialog] = useState({ open: false, data: null })
  const loc = useLocation()
  let formikPropsData;
  if(loc.state !== undefined) {
    formikPropsData = {
          ...FormProperties.formikProps,
          initialValues: FormProperties.toClientDataFormat(loc.state)     
    }
  } else {
    formikPropsData = FormProperties.formikProps
  }
  const { securedSendRequest } = useServices()
  const { translate } = useTranslation(Namespace.ACI_AIR)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { id } = useParams()
  const [confirmDialog, setConfirmDialog] = useState(false)
  const [loading] = useState(false)
  const serverDataRef = useRef()
  const [chargeTypes, setChargeTypes] = useState([])
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { filter: { EQUAL } } = constants
  const [isSuperParty, setSuperParty] = useState(false);
  
  function makeTranslatedTextsObject() {
    const submitButton = translate(
      Namespace.ACI_AIR,
      AciAirKeys.SUBMIT_BUTTON
    )
    const submitRecordDialogContent = translate(
      Namespace.ACI_AIR,
      AciAirKeys.SUBMIT_RECORD_DIALOG_CONTENT
    )
    const submitSuccessMsg = translate(
      Namespace.ACI_AIR,
      AciAirKeys.SUBMIT_SUCCESS_MSG
    )

    return {
      submitButton,
      submitRecordDialogContent,
      submitSuccessMsg
    }
  }

  useEffect(() => {
    Promise.all([
      // Charge type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_CHARGE_TYPE' }],
        undefined,
        'code'
      ),
      // Super party
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_SUPER_PARTY' }],
        undefined, 'code'
      )
    ]).then(
      ([billingChargeTypes, billingSuperParty]) => {
        compileChargeTypes(billingChargeTypes)

        Object.keys(billingSuperParty).forEach(e => {
          if (e == GetLoggedInUserPartyName()) {
            setSuperParty(true);
          }
        })
      }
    )
  }, [])

  function compileChargeTypes(billingChargeTypes) {
    var jsonArray = [];
    if (billingChargeTypes) {
      Object.entries(billingChargeTypes).forEach((e) => {
        var jsonObject = { code: e[0], desc: e[1] }
        jsonArray.push(jsonObject)
      })
    }
    setChargeTypes(jsonArray)
  }
  
  function preSubmit() {
    const values = { ...serverDataRef.current, id }

    if (isSuperParty) {
      setSubmitDialog({ open: true, items: values })
    } else {
      values.chargeAs = 'REGULAR';
      serverDataRef.current = values;
      handleSubmitAciAirSsr();
    }
  }
  
  function handleSubmitAciAirSsr() {
    onSetLoading(true)
      
    const instance = axios.create({
      withCredentials: false,
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userToken'))
      }
    })

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      serverDataRef.current.fileForUserId = fileForUserDetails.fileForUserId;
      serverDataRef.current.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      serverDataRef.current.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }
	
    instance
      .post(
        AciAirSsrApiUrls.SUBMIT,
        DataFlattener.unflatten(FormProperties.toServerDataFormat(serverDataRef.current))
      )
      .then((result) => {
        var returnData = result.data
        var errorMessages = returnData.errorMessages
        if (
          result.status == 200 &&
          returnData != null &&
          errorMessages == null
        ) {
          showNotification('success', translatedTextsObject.submitSuccessMsg)
          history.push(pathMap.ACI_AIR_SSR_LIST_VIEW)
        } else {
          showNotification('error', errorMessages)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => onSetLoading(false))
  }

  return (
    <>
    <Grid container spacing={3}>
	  <Grid item xs={12}>
      <Card>
        <Stepper steps={['Provide details']} activeStep={0} />
        <Box p={2}>
          <CngAddForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            history={history}
            showNotification={showNotification}
            formikProps={formikPropsData}
            toClientDataFormat={FormProperties.toClientDataFormat}
            toServerDataFormat={FormProperties.toServerDataFormat}
            bodySection={
              <FormProperties.Fields showNotification={showNotification} />
            }
            create={{
              url: AciAirSsrApiUrls.POST,
              successRedirect: pathMap.ACI_AIR_SSR_LIST_VIEW
            }}
            renderButtonSection={() => (
              <ButtonSection
                history={history}
                loading={loading}
                onSetLoading={onSetLoading}
                showNotification={showNotification}
                onSubmitReport={(data) => {
                  serverDataRef.current = data
                  preSubmit()
                }}
              />
             )}
          />
        </Box>
      </Card>
      </Grid>
	  <ChargeTypeDialog
        chargeTypes={chargeTypes}
        onClose={() => setSubmitDialog({ open: false, items: [] })}
        onSubmit={(data) => {
          serverDataRef.current = data
          handleSubmitAciAirSsr()
        }}
        open={submitDialog.open}
        items={submitDialog.items}
      	/>
   	  </Grid>
    </>
  )
}

function ButtonSection(props) {
  const { loading, onSetLoading, onSubmitReport, onDiscard } = props
  const [confirmDialog, setConfirmDialog] = useState(false)
  const { formState: { isSubmitting }, getValues, trigger } = useFormContext()

  useEffect(() => {
    onSetLoading(isSubmitting)
  }, [isSubmitting])

  async function handleSubmit() {
    const result = await trigger()

    if (result) {
      onSubmitReport(getValues())
    }
  }

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton
            color='secondary'
            disabled={isSubmitting}
            onClick={() => setConfirmDialog(true)}
            size='medium'
          >
            Discard
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <Grid container spacing={2}>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={isSubmitting}
                type='submit'
                size='medium'
              >
                Save as draft
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                color='secondary'
                size='medium'
                disabled={isSubmitting || loading}
                onClick={async () => {
	                let result = await trigger()
	                if (result) {
	                  onSubmitReport(getValues())
	                }
	            }}
                >
                Submit
              </CngButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AlertDialog
        cancelLabel='Continue editing'
        confirmLabel='Yes, discard'
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        onCancel={() => setConfirmDialog(false)}
        onConfirm={onDiscard}
        title='Discard'
      >
        All progress in this session will be lost and cant't be restored. Are
        you sure about this?
      </AlertDialog>
    </>
  )
}

export default withFormState(AddPage)
