import { Box, Card, Grid } from '@material-ui/core'
import React, { useRef, useEffect, useState } from 'react'
import {
  components,
  DataFlattener,
  useServices,
  useTranslation,
  constants
} from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import pathMap from '../../../paths/pathMap'
import Stepper from '../../../components/aciacehighway/Stepper'
import FormProperties from './FormProperties'
import withFormState from '../../../components/aciacehighway/hocs/withFormState'
import { useFormContext } from 'react-hook-form'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import AciAirSsrApiUrls from '../../../apiUrls/AciAirSsrApiUrls'
import Namespace from '../../../constants/locale/Namespace'
import AciAirKeys from '../../../constants/locale/key/AciAir'
import ViewLogsHistory from '../aciaircommon/ViewLogsHistory'
import ConfirmDialog from '../../common/ConfirmDialog'
import axios from 'axios'
import ChargeTypeDialog from '../../common/ChargeTypeDialog'
import { FileForUserGetUserDetails, GetLoggedInUserPartyName } from 'src/common/FileForUserCommon'

const {
  button: { CngButton },
  form: { CngEditForm },
  table: {
    useFetchCodeMaintenanceLookup
  }
} = components

function EditPage(props) {
  const { history, onSetLoading, showNotification } = props

  const [currentStep, setCurrentStep] = useState(0)
  const [submitDialog, setSubmitDialog] = useState({ open: false, items: [] })
  const { id } = useParams()
  const { securedSendRequest } = useServices()
  const { translate } = useTranslation(Namespace.ACI_AIR)
  const translatedTextsObject = makeTranslatedTextsObject()

  const [ccnValue, setCCNValue] = useState('')
  const [confirmDialog, setConfirmDialog] = useState(false)
  const [loading] = useState(false)
  const serverDataRef = useRef()
  const [chargeTypes, setChargeTypes] = useState([])
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { filter: { EQUAL } } = constants
  const [isSuperParty, setSuperParty] = useState(false);
  
  function makeTranslatedTextsObject() {
    const submitButton = translate(
      Namespace.ACI_AIR,
      AciAirKeys.SUBMIT_BUTTON
    )
    const submitRecordDialogContent = translate(
      Namespace.ACI_AIR,
      AciAirKeys.SUBMIT_RECORD_DIALOG_CONTENT
    )
    const submitSuccessMsg = translate(
      Namespace.ACI_AIR,
      AciAirKeys.SUBMIT_SUCCESS_MSG
    )

    return {
      submitButton,
      submitRecordDialogContent,
      submitSuccessMsg
    }
  }
  
    useEffect(() => {
    Promise.all([
      // Charge type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_CHARGE_TYPE' }],
        undefined,
        'code'
      ),
      // Super party
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_SUPER_PARTY' }],
        undefined, 'code'
      )
    ]).then(
      ([billingChargeTypes, billingSuperParty]) => {
        compileChargeTypes(billingChargeTypes)

        Object.keys(billingSuperParty).forEach(e => {
          if (e == GetLoggedInUserPartyName()) {
            setSuperParty(true);
          }
        })
      }
    )
  }, [])

  function compileChargeTypes(billingChargeTypes) {
    var jsonArray = [];
    if (billingChargeTypes) {
      Object.entries(billingChargeTypes).forEach((e) => {
        var jsonObject = { code: e[0], desc: e[1] }
        jsonArray.push(jsonObject)
      })
    }
    setChargeTypes(jsonArray)
  }

  function preSubmit() {
    const values = { ...serverDataRef.current, id }

    if (isSuperParty) {
      setSubmitDialog({ open: true, items: values })
    } else {
      values.chargeAs = 'REGULAR';
      serverDataRef.current = values;
      handleSubmitAciAirSsr();
    }
  }
  
  function handleSubmitAciAirSsr() {
    onSetLoading(true)
      
    const instance = axios.create({
      withCredentials: false,
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userToken'))
      }
    })

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      serverDataRef.current.fileForUserId = fileForUserDetails.fileForUserId;
      serverDataRef.current.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      serverDataRef.current.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    instance
      .post(
        AciAirSsrApiUrls.SUBMIT,
        DataFlattener.unflatten(FormProperties.toServerDataFormat(serverDataRef.current))
      )
      .then((result) => {
        var returnData = result.data
        var errorMessages = returnData.errorMessages
        if (
          result.status == 200 &&
          returnData != null &&
          errorMessages == null
        ) {
          showNotification('success', translatedTextsObject.submitSuccessMsg)
          history.push(pathMap.ACI_AIR_SSR_LIST_VIEW)
        } else {
          showNotification('error', errorMessages)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => onSetLoading(false))
  }

  function renderContent(step) {
    switch (step) {
      case 0:
        return (
	    <Grid container spacing={3}>
		  <Grid item xs={12}>
          <CngEditForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            showNotification={showNotification}
            bodySection={
              <FormProperties.Fields showNotification={showNotification} />
            }
            formikProps={FormProperties.formikProps}
            toClientDataFormat={FormProperties.toClientDataFormat}
            toServerDataFormat={FormProperties.toServerDataFormat}
            fetch={{
              url: AciAirSsrApiUrls.GET,
              onPreSuccess: (serverData) => setCCNValue(serverData.ccn)
            }}
            update={{
              url: AciAirSsrApiUrls.PUT
            }}
            id={id}
            renderButtonSection={() => (
              <ACIAirEditButtonSection
                history={history}
                loading={loading}
                onSetLoading={onSetLoading}
                showNotification={showNotification}
                onSubmitReport={(data) => {
                  serverDataRef.current = data
                  preSubmit()
                }}
              />
             )}
          />
      </Grid>
	  <ChargeTypeDialog
        chargeTypes={chargeTypes}
        onClose={() => setSubmitDialog({ open: false, items: [] })}
        onSubmit={(data) => {
          serverDataRef.current = data
          handleSubmitAciAirSsr()
        }}
        open={submitDialog.open}
        items={submitDialog.items}
      	/>
    	</Grid>
      )
      case 1:
        return (
          <LogsAndHistory
            ccnValue={ccnValue}
            headerId={id}
            onBack={() => setCurrentStep((prev) => prev - 1)}
            onDiscard={() => setConfirmDialog(true)}
            showNotification={showNotification}
          />
        )
    }
  }

  return (
    <>
      <Card>
        <Stepper
          activeStep={currentStep}
          onSetActiveStep={setCurrentStep}
          steps={['Provide details', 'View logs & history']}
        />
        <Box p={2}>{renderContent(currentStep)}</Box>
      </Card>
      <AlertDialog
        cancelLabel='Continue editing'
        confirmLabel='Yes, discard'
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        onCancel={() => setConfirmDialog(false)}
        onConfirm={() => history.push(pathMap.ACI_AIR_SSR_LIST_VIEW)}
        title='Discard'
      >
        All progress in this session will be lost and cant't be restored. Are
        you sure about this?
      </AlertDialog>
    </>
  )
}

function ACIAirEditButtonSection(props) {
  const { history, loading, onSetLoading, onSubmitReport, onDiscard } = props
  const [confirmDialog, setConfirmDialog] = useState(false)
  const { formState: { isSubmitting }, getValues, trigger } = useFormContext()
  const editTemplate = getValues() ? getValues().isEditTemplate : false;


  useEffect(() => {
    onSetLoading(isSubmitting)
  }, [isSubmitting])

  async function handleSubmit() {
    const result = await trigger()

    if (result) {
      onSubmitReport(getValues())
    }
  }

  return (
    <Grid container justify='space-between' spacing={3}>
      <Grid item xs='auto'>
        <CngButton
          color='secondary'
          disabled={isSubmitting}
          onClick={onDiscard}
          size='medium'
        >
          Discard
        </CngButton>
      </Grid>
      <Grid item xs='auto'>
        <Grid container spacing={2}>
          <Grid item xs='auto'>
            <CngButton
              color='primary'
              disabled={isSubmitting}
              type='submit'
              size='medium'
            >
              Save as draft
            </CngButton>
          </Grid>
            {!editTemplate && <>
              <Grid item xs='auto'>
                <CngButton
                  color='primary'
                  disabled={isSubmitting || loading}
                  //shouldShowProgress={loading}
                  onClick={async () => {
                    let result = await trigger()
                    if (result) {
                      onSubmitReport(getValues())
                    }
                  }}
                >
                  Submit
                </CngButton>
              </Grid>
            </>}
        </Grid>
      </Grid>
    </Grid>
  )
}

function LogsAndHistory(props) {
  const { ccnValue, headerId, onBack, onDiscard, showNotification } = props

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ViewLogsHistory
          ccnValue={ccnValue}
          headerId={headerId}
          showNotification={showNotification}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container justify='space-between' spacing={3}>
          <Grid item xs='auto'>
            <CngButton color='secondary' onClick={onDiscard} size='medium'>
              Discard
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <CngButton color='secondary' onClick={onBack} size='medium'>
              Back
            </CngButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withFormState(EditPage)
