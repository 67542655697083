import React, { useContext, useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import AciAirKeys from '../../../../constants/locale/key/AciAir'
import { LookupsContext } from '../../../../contexts/LookupsContext'
import StatusBar from '../../../../components/aciacehighway/StatusBar'
import CngField from '../../../../components/cngcomponents/CngField'
import CngSection from '../../../../components/cngcomponents/CngSection'
import TinyChip from '../../../../components/aciacehighway/TinyChip'
import LocalTable from '../../../../components/aciacehighway/LocalTable'
import ItemViewContent from './ItemViewContent'
import { Box, Card, CircularProgress, Divider, Grid, Typography, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import _ from 'lodash'
import ViewLogsHistory from '../../aciaircommon/ViewLogsHistory'

const { button: { CngButton }, CngDialog } = components

function ReportViewContent(props) {
  const { data, reportId, showNotification } = props

  const [itemDialog, setItemDialog] = useState({ open: false, item: null })
  const { getLookupValue } = useContext(LookupsContext)
  const { translate } = useTranslation(Namespace.ACI_AIR)
  const translatedTextsObject = makeTranslatedTextsObject()
  const theme = useTheme()

  function makeTranslatedTextsObject() {
    const schedule = translate(Namespace.ACI_AIR, AciAirKeys.SCHEDULE)
    const customsProc = translate(Namespace.ACI_AIR, AciAirKeys.CUSTOMS_PROC)
    const originalCCN = translate(Namespace.ACI_AIR, AciAirKeys.ORIGINAL_C_C_N)
    const ccn = translate(Namespace.ACI_AIR, AciAirKeys.CCN)
    const carrierCode = translate(Namespace.ACI_AIR, AciAirKeys.CARRIER_CODE)
    const deliveryCountry = translate(
      Namespace.ACI_AIR,
      AciAirKeys.DELIVERY_COUNTRY
    )
    const deliveryCity = translate(Namespace.ACI_AIR, AciAirKeys.DELIVERY_CITY)
    const specialIns = translate(Namespace.ACI_AIR, AciAirKeys.SPECIAL_INS)
    const scheduleDateAndTime = translate(
      Namespace.ACI_AIR,
      AciAirKeys.SCHEDULE_DATE_TIME
    )
    const deliveryTerminalName = translate(
      Namespace.ACI_AIR,
      AciAirKeys.DELIVERY_TERMINAL_NAME
    )
    const aciAirPartyList = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirPartyList.TITLE
    )
    const partyType = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirPartyList.PARTY_TYPE
    )
    const partyName1 = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirPartyList.PARTY_NAME1
    )
    const partyName2 = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirPartyList.PARTY_NAME2
    )
    const address1 = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirPartyList.ADDRESS1
    )
    const address2 = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirPartyList.ADDRESS2
    )
    const city = translate(Namespace.ACI_AIR, AciAirKeys.AciAirPartyList.CITY)
    const stateCode = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirPartyList.STATE_CODE
    )
    const postalCode = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirPartyList.POSTAL_CODE
    )
    const countryCode = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirPartyList.COUNTRY_CODE
    )
    const contactPerson = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirPartyList.CONTACT_PERSON
    )
    const telNo = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirPartyList.TEL_NO
    )
    const aciAirItemList = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.TITLE
    )
    const itemNo = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.ITEM_NO
    )
    const ladingQuantity = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.LADING_QUANTITY
    )
    const ladingUom = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.LADING_UOM
    )
    const productDesc = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.PRODUCT_DESC
    )
    const grossWeightUom = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.GROSS_WEIGHT_UOM
    )
    const grossWeight = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.GROSS_WEIGHT
    )
    const aciAirStatusNotifyList = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirStatusNotifyList.TITLE
    )
    const statusType = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirStatusNotifyList.STATUS_TYPE
    )
    const email = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirStatusNotifyList.EMAIL
    )
    const phoneNumber = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirStatusNotifyList.PHONE_NUMBER
    )

    return {
      itemNo,
      productDesc,
      ladingQuantity,
      ladingUom,
      grossWeight,
      grossWeightUom,
      carrierCode,
      originalCCN,
      ccn,
      customsProc,
      specialIns,
      schedule,
      deliveryCountry,
      deliveryCity,
      deliveryTerminalName,
      scheduleDateAndTime,
      aciAirPartyList,
      partyType,
      partyName1,
      partyName2,
      address1,
      address2,
      city,
      stateCode,
      postalCode,
      countryCode,
      contactPerson,
      telNo,
      aciAirItemList,
      aciAirStatusNotifyList,
      email,
      phoneNumber,
      statusType
    }
  }

  const itemTableColumns = [
    {
      field: 'itemNo',
      sortKey: 'itemNo',
      title: translatedTextsObject.itemNo
    },
    {
      field: 'productDesc',
      sortKey: 'productDesc',
      title: translatedTextsObject.productDesc
    },
    {
      field: 'ladingQuantity',
      sortKey: 'ladingQuantity',
      title: translatedTextsObject.ladingQuantity
    },
    {
      field: 'ladingUom',
      sortKey: 'ladingUom',
      title: translatedTextsObject.ladingUom,
      render: (rowData) => (
        <Typography component='div' variant='inherit'>
          {getLookupValue('uomType', rowData.ladingUom)}
          <TinyChip label={rowData.ladingUom} variant='outlined' />
        </Typography>
      )
    },
    {
      field: 'grossWeight',
      sortKey: 'grossWeight',
      title: translatedTextsObject.grossWeight
    },
    {
      field: 'grossWeightUom',
      sortKey: 'grossWeightUom',
      title: translatedTextsObject.grossWeightUom,
      render: (rowData) => (
        <Typography component='div' variant='inherit'>
          {getLookupValue('grossWeightUom', rowData.grossWeightUom)}
          <TinyChip label={rowData.grossWeightUom} variant='outlined' />
        </Typography>
      )
    }
  ]

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <StatusBar status={data.hdrStatus} />
      </Grid>
      <Grid item xs={12}>
        <Card variant='outlined'>
          <Box padding={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box mb={2}>
                  <Typography variant='h5' style={{ fontWeight: 600 }}>
                    General Information
                  </Typography>
                </Box>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={translatedTextsObject.carrierCode}>
                      {data.carrierCode}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={translatedTextsObject.originalCCN}>
                      {data.originalCCN}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={translatedTextsObject.ccn}>
                      {data.ccn}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={translatedTextsObject.customsProc}>
                      {data.customsProc && (
                        <Typography component='div' variant='inherit'>
                          {getLookupValue('customsProc', data.customsProc)}
                          <TinyChip label={data.customsProc} variant='outlined' />
                        </Typography>
                      )}
                    </CngField>
                  </Grid>
                  <Grid item xs={12}>
                    <CngField label={translatedTextsObject.specialIns}>
                      {data.specialIns}
                    </CngField>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <CngSection
                  action={
                    <Box display='flex' alignItems='center' style={{ gap: 8 }}>
                      <FontAwesomeIcon
                        color={
                          data.scheduledSubmissionFlag
                            ? theme.palette.success.main
                            : theme.palette.error.main
                        }
                        icon={['fal', data.scheduledSubmissionFlag ? 'circle-check' : 'ban']}
                      />
                      <Typography
                        color='textSecondary'
                        variant='body2'
                        style={{ fontWeight: 600 }}
                      >
                        Schedule Delivery
                      </Typography>
                    </Box>
                  }
                  title={translatedTextsObject.schedule}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={translatedTextsObject.deliveryCountry}>
                        <Typography component='div' variant='inherit'>
                          {getLookupValue('country', data.deliveryCountry)}
                          <TinyChip label={data.deliveryCountry} variant='outlined' />
                        </Typography>
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={translatedTextsObject.deliveryCity}>
                        {data.deliveryCity}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={translatedTextsObject.deliveryTerminalName}>
                        {data.deliveryTerminalName}
                      </CngField>
                    </Grid>
                    {data.scheduledSubmissionFlag &&
                      data.scheduleDateAndTime && (
                        <Grid item xs={12} sm={6} md={3}>
                          <CngField label={translatedTextsObject.scheduleDateAndTime}>
                            {moment(data.scheduleDateAndTime).format('DD/MM/YYYY HH:mm:ss')}
                          </CngField>
                        </Grid>
                      )}
                  </Grid>
                </CngSection>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.aciAirPartyList}>
          {!_.isEmpty(data.aciAirPartyList) ? (
            <Grid container spacing={2}>
              {data.aciAirPartyList.map((party) => (
                <Grid key={party.id} item xs={12}>
                  <Card variant='outlined' style={{ backgroundColor: theme.palette.grey[100] }}>
                    <Box padding={2}>
                      <Grid container spacing={2}>
                        <Grid container spacing={2} item xs={12}>
                          <Grid item xs={12} sm={6} md={3}>
                            <CngField label={translatedTextsObject.partyType}>
                              {getLookupValue('partyType', party.partyType)}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <CngField label={translatedTextsObject.partyName1}>
                              {party.partyName1}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <CngField label={translatedTextsObject.partyName2}>
                              {party.partyName2}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <CngField label={translatedTextsObject.address1}>
                              {party.address1}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <CngField label={translatedTextsObject.address2}>
                              {party.address2}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <CngField label={translatedTextsObject.city}>
                              {party.city}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <CngField label={translatedTextsObject.stateCode}>
                              {party.stateCode}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <CngField label={translatedTextsObject.postalCode}>
                              {party.postalCode}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <CngField label={translatedTextsObject.countryCode}>
                              <Typography component='div' variant='inherit'>
                                {getLookupValue('country', party.countryCode)}
                                <TinyChip label={party.countryCode} />
                              </Typography>
                            </CngField>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Card variant='outlined'>
                            <Box padding={2}>
                              <Grid alignItems='center' container spacing={1}>
                                <Grid item xs={12} md={3}>
                                  <Typography
                                    color='textSecondary'
                                    variant='body1'
                                    style={{ fontWeight: 600 }}
                                  >
                                    Contact Person
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <CngField label={translatedTextsObject.contactPerson}>
                                    {party.contactPerson}
                                  </CngField>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <CngField label={translatedTextsObject.telNo}>
                                    {party.telNo}
                                  </CngField>
                                </Grid>
                              </Grid>
                            </Box>
                          </Card>
                        </Grid>
                      </Grid>
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography color='textSecondary'>
              No records to display
            </Typography>
          )}
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <Card variant='outlined'>
          <LocalTable
            columns={itemTableColumns}
            data={data.aciAirItemList}
            header={
              <Typography
                component='div'
                variant='h5'
                style={{ fontWeight: 600 }}
              >
                {translatedTextsObject.aciAirItemList}
              </Typography>
            }
            onRowClick={(data) => setItemDialog({ open: true, item: data })}
          />
          <ItemDialog
            open={itemDialog.open}
            onClose={() => setItemDialog({ open: false, item: null })}
            title='Commodity'
            item={itemDialog.item}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.aciAirStatusNotifyList}>
          {!_.isEmpty(data.aciAirStatusNotifyList) ? (
            <Grid container spacing={2}>
              {data.aciAirStatusNotifyList.map((statusNotification, index) => (
                <React.Fragment key={statusNotification.id}>
                  <Grid container spacing={2} item xs={12}>
                    <Grid item xs={12} md={4}>
                      <CngField label={translatedTextsObject.email}>
                        {statusNotification.email}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <CngField label={translatedTextsObject.phoneNumber}>
                        {statusNotification.phoneNumber}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <CngField label={translatedTextsObject.statusType}>
                        {getLookupValue('statusType', statusNotification.statusType)}
                      </CngField>
                    </Grid>
                  </Grid>
                  {data.aciAirStatusNotifyList.length !== index + 1 && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          ) : (
            <Typography color='textSecondary'>
              No records to display
            </Typography>
          )}
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <ViewLogsHistory
          ccnValue={data.ccn}
          headerId={reportId}
          showNotification={showNotification}
        />
      </Grid>
    </Grid>
  )
}

function ItemDialog(props) {
  const { onClose, open, item, title } = props

  return (
    <CngDialog
      customDialogContent={
        <Box p={2}>
          {item ? (
            <ItemViewContent item={item} />
          ) : (
            <Box display='flex' alignItems='center' justifyContent='center'>
              <CircularProgress />
            </Box>
          )}
        </Box>
      }
      dialogAction={<CngButton onClick={onClose}>OK</CngButton>}
      dialogTitle={title}
      maxWidth='md'
      fullWidth
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}

export default ReportViewContent
