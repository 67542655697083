import AddPage from './AddPage.js'
import { BasePage as CngBasePage } from 'cng-web-lib'
import EditPage from './EditPage.js'
import React from 'react'
import TablePage from './TablePage.js'
import ViewPage from './ViewPage.js'
import useBreadcrumbNameMap from 'src/useBreadcrumbNameMap'
import S10LookupsProvider from '../lookups/S10LookupsContext'

function WrappedTablePage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <S10LookupsProvider>
      <CngBasePage
        renderPage={(showSnackbar) => (
          <TablePage showNotification={showSnackbar} {...props} />
        )}
        extraCngBreadcrumbNameMap={breadcrumbNameMap}
      />
    </S10LookupsProvider>
  )
}

function WrappedAddPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <S10LookupsProvider>
      <CngBasePage
        renderPage={(showSnackbar) => (
          <AddPage showNotification={showSnackbar} {...props} />
        )}
        extraCngBreadcrumbNameMap={breadcrumbNameMap}
      />
    </S10LookupsProvider>
  )
}

function WrappedEditPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <S10LookupsProvider>
      <CngBasePage
        renderPage={(showSnackbar) => (
          <EditPage showNotification={showSnackbar} {...props} />
        )}
        extraCngBreadcrumbNameMap={breadcrumbNameMap}
      />
    </S10LookupsProvider>
  )
}

function WrappedViewPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <S10LookupsProvider>
      <CngBasePage
        renderPage={(showSnackbar) => (
          <ViewPage showNotification={showSnackbar} {...props} />
        )}
        extraCngBreadcrumbNameMap={breadcrumbNameMap}
      />
    </S10LookupsProvider>
  )
}

export {
  WrappedAddPage as AddPage,
  WrappedEditPage as EditPage,
  WrappedViewPage as ViewPage,
  WrappedTablePage as TablePage
}
